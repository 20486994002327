$sidebar-bg-color: #eae9d0 !default;
$sidebar-color: #343a40 !default;
$sidebar-width: 270px !default;
$sidebar-collapsed-width: 80px !default;
$highlight-color: #6c757d !default;
$submenu-bg-color: #eae9d0 !default;
$submenu-bg-color-collapsed: #eae9d0 !default;
$icon-bg-color: #eae9d0 !default;
$icon-size: 35px !default;
/* $breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default; */

@import "~react-pro-sidebar/dist/scss/styles.scss";
